import React from 'react'
import Layout from '../components/layout'

import bg from '../images/eat_fit_abq_contact.png'

const Contact = () => (
  <Layout>
    <img
      style={{ position: 'absolute', right: 10, top: 10 }}
      src={bg}
      alt="background"
    />
    <div
      className="header"
      style={{
        position: 'absolute',
        left: 250,
        top: 40,
      }}
    >
      Next Week's Menu
    </div>
    <div
      style={{
        width: 675,
        height: 675,
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontSize: 14,
        position: 'absolute',
        top: 83,
        left: 250,
        overflowY: 'scroll',
      }}
    >
      <p>Week Of 8/8/22</p>
      <p>Lunch/Dinner:</p>

      <div class="menu-item">
        Chicken Cordon Bleu with Low-Fat Swiss Cheese, No Nitrate Turkey Bacon,
        Potato Mash and Asparagus
      </div>
      <div class="menu-desc">
        Small Cal 351 Fat 10.4 Carb 22.7 Protein 39.8
        <br />
        Large Cal 404 Fat 11.5 Carb 22.7 Protein 49.5
      </div>

      <div class="menu-item">
        Pork Sirloin Cabbage StirFry over Brown Rice( pork sirloin , spicy pork
        sausage, carrots, napa cabbage, broccoli, sesame oil, coconut aminos,
        sriracha)
      </div>
      <div class="menu-desc">
        Small Cal 376 Fat 12.9 Carb 31.9 Protein 29.5
        <br />
        Large Cal 405 Fat 13.9 Carb 31.9 Protein 33.5
      </div>

      <div class="menu-item">
        Mexican Stuffed Bell Peppers( all natural ground turkey,
        onion,cauliflower rice, tomatoes, almond milk cheese, jalapeños, spices,
        tomato sauce)
      </div>
      <div class="menu-desc">Cal 274 Fat 12.7 Carb 10.1 Protein 34.9</div>

      <div class="menu-item">
        Roasted Chicken with Creamy Spinach and Artichoke Sauce, Broccoli and
        Roasted Cauliflower with Turkey Bacon Bits( all natural chicken
        breast,no nitrate turkey bacon, lowfat coconut milk , tapioca flour)
      </div>
      <div class="menu-desc">
        Small Cal 214 Fat 7.2 Carb 10.3 Protein 30.1
        <br />
        Large Cal 269 Fat 7.7 Carb 10.3 Protein 43.6
      </div>

      <div class="menu-item">
        Chicken and Green Chile Cheddar Waffles with Dijon Maple Syrup( all
        natural chicken breast, almond flour)
      </div>
      <div class="menu-desc">
        CAL 344 FAT 8.3 Carb 50.3 Protein 29.4
        <br />
        <div class="menu-item">
          Sub Chaffles( egg, almond flour, mozzarella)
        </div>
        CAL 416 FAT 21.8 CARB 4.8 PROTEIN 47.8
        <br />
        (KETO)
      </div>

      <p>The above Meals will be delivered Sunday for Mon-Wed</p>

      <div class="menu-item">
        Paleo Potsticker Meatballs with Brown Rice and Broccoli( ground turkey,
        ground pork sirloin ,carrots, coconut aminos, egg, cilantro)
      </div>
      <div class="menu-desc">
        Small Cal 293 Fat 5.4 Carb 31.4 Protein 30.9
        <br />
        Large Cal 326 Fat 6.3 Carb 31.4 Protein 37.4
      </div>

      <div class="menu-item">
        Smoked Paprika Chicken Thigh with Sweet Potato Mash and Brussels( all
        natural chicken thigh, spices)
      </div>
      <div class="menu-desc">
        Small Cal 280 Fat 9.6 Carb 20.7 Protein 29.9
        <br />
        Large Cal 373 Fat 14.3 Carb 20.7 Protein 42.6
      </div>

      <div class="menu-item">
        Crustless Taco Pie( all natural ground turkey, egg, tomato, onion,
        jalapeno, nonfat greek yogurt, romaine lettuce)
      </div>
      <div class="menu-desc">CAL 332 Fat 14.4 Carb 7.2 Protein 45.5</div>

      <div class="menu-item">
        Ground Turkey Meatballs with Home Made Marinara , Broccoli over
        Spaghetti Squash ( onion, tomatoes, basil, garlic)
      </div>
      <div class="menu-desc">
        Small Cal 258 Fat 9.0 Carb 21.8 Protein 26.1
        <br />
        Large Cal 333 Fat 13 Carb 21.8 Protein 37.1
      </div>

      <div class="menu-item">
        Paleo Cashew Chicken over Cauli Rice( chicken breast, carrots,snap
        peas,zucchini, cashews, honey, coconut aminos, tomato paste)
      </div>
      <div class="menu-desc">
        Small Cal 331 Fat 10.3 Carb 18.5 Protein 38.6
        <br />
        Large Fat 378 Fat 11.3 Carb 18.5 Protein 47.3
      </div>

      <p>The above Meals will be delivered Wednesday for Thur-Sun</p>

      <p>Breakfast: 10$</p>

      <div class="menu-item">
        Hazelnut Pancakes with Chocolate Coconut Cream Sauce( gluten free
        pancake mix, crushed hazelnuts,coconut cream,maple syrup,cocoa powder) 4
        Cakes
      </div>
      <div class="menu-desc">CAL 360 FAT 14.6 CARB 45.6 PROTEIN 17.0</div>

      <div class="menu-item">
        Italian Sausage Egg White Bites with Garlic Parmesan Broccoli(all
        natural ground pork, tomatoes,mozzarella cheese,Italian spices,spinach,
        sundried tomatoes)KETO,PALEO
      </div>
      <div class="menu-desc">Cal 268 Fat 7.0 Carb 17.3 Protein 34.3</div>
    </div>
  </Layout>
)

export default Contact
